// src/App.js
import React from "react"
import GiveawayForm from "./components/form"
import "./App.css"

function App() {
  return (
    <div className="App">
      <GiveawayForm />
    </div>
  )
}

export default App
