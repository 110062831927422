import React, { useState, useEffect } from "react";
import { collection, addDoc, query, where, getDocs } from "firebase/firestore";
import { db } from "../firebase";

function GiveawayForm() {
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [phone, setPhone] = useState("");
  const [no, setNo] = useState("")
  const [message, setMessage] = useState("");
  const [eventId, setEventId] = useState("");
  const [events, setEvents] = useState([]);

  // Fetch events from Firestore
  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const eventsRef = collection(db, "events");
        const eventsSnapshot = await getDocs(eventsRef);
        const eventsList = eventsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setEvents(eventsList);
        if (eventsList.length > 0) {
          setEventId(eventsList[0].name); // Set default event name
        }
      } catch (error) {
        console.error("Error fetching events: ", error);
      }
    };

    fetchEvents();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!eventId) {
        setMessage("Lütfen bir etkinlik seçin.");
        return;
      }

      const participantsRef = collection(db, "participants");
      const q = query(
        participantsRef,
        where("no", "==", no),
        where("eventId", "==", eventId)
      );
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        setMessage("Bu etkinliğe zaten katılmışsınız.");
      } else {
        await addDoc(collection(db, "participants"), {
          eventId,
          name,
          surname,
          phone,
          no,
          timestamp: new Date(),
        });
        setMessage("Başarıyla kayıt olundu!");
        setName("");
        setPhone("");
        setSurname("");
        setNo("");
      }
    } catch (error) {
      console.error("Error adding document: ", error);
      setMessage("Kayıt esnasında bir hata meydana geldi. Lütfen tekrar deneyin.");
    }
  };

  return (
    <div className="max-w-md mx-auto p-8 bg-white shadow-2xl rounded-md mt-10">
      <h2 className="text-2xl font-bold mb-6 text-center">Çekilişe katıl</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        {/* Event Dropdown */}
        <div>
          <label className="block text-gray-700 font-semibold mb-2">Etkinlik Seç:</label>
          <select
            value={eventId}
            onChange={(e) => setEventId(e.target.value)}
            required
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            {events.length > 0 ? (
              events.map((event) => (
                <option key={event.id} value={event.name}>
                  {event.name}
                </option>
              ))
            ) : (
              <option disabled>Yükleniyor...</option>
            )}
          </select>
        </div>

        {/* Name Input */}
        <div>
          <label className="block text-gray-700 font-semibold mb-2">Ad:</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        {/* Surname Input */}
        <div>
          <label className="block text-gray-700 font-semibold mb-2">Soyad:</label>
          <input
            type="text"
            value={surname}
            onChange={(e) => setSurname(e.target.value)}
            required
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
        {/* School Number Input */}
        <div>
          <label className="block text-gray-700 font-semibold mb-2">Okul Numarası:</label>
          <input
            type="text"
            value={no}
            onChange={(e) => setNo(e.target.value)}
            required
            maxLength={10}
            pattern="\d{10}"
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        {/* Phone Input */}
        <div>
          <label className="block text-gray-700 font-semibold mb-2">Telefon Numarası:</label>
          <text className="text-gray-600">(Başında 0 olmadan yazınız.)</text>
          <input
            type="tel"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            required
            maxLength={10}
            pattern="\d{10}"
            placeholder="5553335566"
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        {/* Submit Button */}
        <button
          type="submit"
          className="w-full bg-purple-600 text-white py-2 rounded-md hover:bg-purple-500 transition duration-300"
        >
          Gönder
        </button>
      </form>
      {message && (
        <p
          className={`mt-4 text-center text-lg font-semibold ${
            message === "Başarıyla kayıt olundu!" ? "text-green-500" : "text-red-500"
          }`}
        >
          {message}
        </p>
      )}
    </div>
  );
}

export default GiveawayForm;
