// src/firebase.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration (replace with your own Firebase project config)
const firebaseConfig = {
    apiKey: "AIzaSyAKu2eKiYhKR7WlLanlWy38oizztyGbhlk",
    authDomain: "giveaway-962fd.firebaseapp.com",
    projectId: "giveaway-962fd",
    storageBucket: "giveaway-962fd.firebasestorage.app",
    messagingSenderId: "540493073640",
    appId: "1:540493073640:web:c2fe241127f796956b4d5d",
    measurementId: "G-PJ6V01V38N"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };
